<template>
  <v-dialog :value="value" class="dialog" persistent width="1300">
    <v-card color=#fafafa>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Copy TPR - {{tpr.item.orderCode}} - {{tpr.item.description}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row>
          <v-col cols="2">
            <v-text-field background-color="#fff" label="Order Code" dense outlined v-model="tpr.orderCode"
              autocomplete="off" @keydown.enter="getByItemCode">
              <template v-slot:append>
                <v-btn icon small @click="getByItemCode">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col col="2">
            <v-text-field readonly background-color="#fff" dense outlined label="Selection Flag"
              :value="(item.selectionFlag) ? item.selectionFlag : 'N/A'" autcomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field readonly background-color="#fff" dense outlined :value="item.description"
              label="Item Description" autcomplete="off"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field readonly background-color="#fff" dense outlined :value="item.pack" label="Pack"
              autcomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field readonly background-color="#fff" dense outlined :value="item.Size" label="Size"
              autcomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined :value="item.upc" label="UPC" autcomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.caseCost" label="Case Cost" @change="formatCurrencyInput('caseCost', tpr.caseCost)" autcomplete="off"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.AMAP" label="AMAP" @change="formatCurrencyInput('tpr', tpr.AMAP)" autcomplete="off"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.EBA" label="EBA" @change="formatCurrencyInput('tpr', tpr.EBA)" autcomplete="off"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.BB" label="TPR BB" @change="formatCurrencyInput('BB', tpr.BB)" autcomplete="off"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.scanAllow" label="TPR Scan" @change="formatCurrencyInput('scanAllow', scanAllow)" autcomplete="off"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.ePay" label="ePay TPR" @change="formatCurrencyInput('ePay', ePay)" autcomplete="off"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" style="max-width: 183px;">
            <v-text-field background-color="#fff" dense outlined label="Quantity" type="number" min="0"
              v-model="tpr.regSRPCount" autcomplete="off">
            </v-text-field>
          </v-col>
          <v-icon class="mb-6">mdi-at</v-icon>
          <v-col cols="2" class="srp">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.regSRP" label="Reg SRP" @change="formatCurrencyInput('regSRP', tpr.regSRP)" autcomplete="off"></v-text-field>
          </v-col>
          <v-col cols="2" style="max-width: 183px;">
            <v-text-field background-color="#fff" dense outlined label="Quantity" type="number" min="0"
              v-model="tpr.specialSRPCount" autcomplete="off">
            </v-text-field>
          </v-col>
          <v-icon class="mb-6">mdi-at</v-icon>
          <v-col cols="2">
            <v-text-field class="price" background-color="#fff" prefix="$" dense outlined type="number" min="0"
              step="0.01" v-model="tpr.specialSRP" label="TPR Retail" @change="formatCurrencyInput('specialSRP', tpr.specialSRP)" autcomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-autocomplete background-color="#fff" dense outlined label="Department" :items="departments"
              item-text="name" item-value="id" v-model="tpr.department">
            </v-autocomplete>
          </v-col>
          <v-col cols="5">
            <v-autocomplete background-color="#fff" dense outlined label="Manufacturer" :items="manufacturers"
              item-text="description" item-value="id" v-model="tpr.manufacturer">
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined label="Begin Date - Sun" v-model="tpr.dealBeginDate">
              <template v-slot:prepend-inner>
                <v-icon tabindex="-1">mdi-calendar</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined label="End Date - Sat" v-model="tpr.dealEndDate">
              <template v-slot:prepend-inner>
                <v-icon tabindex="-1">mdi-calendar</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field readonly background-color="#fff" dense outlined label="Contract"
              :value="tpr.contract.name">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field readonly background-color="#fff" dense outlined :value="tpr.contract.id"
              label="Contract ID">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field readonly background-color="#fff" dense outlined :value="tpr.comments" label="Comments">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <em>
              <h3 style="color:#0c67a5;">{{tpr.adGroup.description}}</h3>
            </em>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
        <v-spacer></v-spacer>
        <v-btn height="40" color="#00B24A" class="white--text ml-2" @click="copyTPR">
          Add<v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
// Third-Party
import { cloneDeep } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'TprCopy',

  mixins: [notification],

  props: ['value', 'selectedTPR', 'getTPRs'],

  data () {
    return {
      showCurrent: false,
      offset: true,
      selected: [],
      item: {},
      departments: [],
      manufacturers: []
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getDepartments()
          this.getManufacturers()
          this.tpr.dealBeginDate = this.moment(this.tpr.dealBeginDate).format(this.$config.date_display)
          this.tpr.dealEndDate = this.moment(this.tpr.dealEndDate).format(this.$config.date_display)
          const values = {
            caseCost: this.tpr.caseCost,
            AMAP: this.tpr.AMAP,
            EBA: this.tpr.EBA,
            BB: this.tpr.BB,
            scanAllow: this.tpr.scanAllow,
            ePay: this.tpr.ePay,
            regSRP: this.tpr.regSRP,
            specialSRP: this.tpr.specialSRP,
            specialSRP2: this.tpr.specialSRP2
          }
          for (const key in values) {
            this.formatCurrencyInput(key, values[key])
          }
        }
      }
    }
  },

  computed: {
    tpr () {
      return cloneDeep(this.selectedTPR)
    },

    formattedBeginDate () {
      return this.tpr.dealBeginDate ? this.moment(this.tpr.dealBeginDate).format(this.$config.date_format) : ''
    },

    formattedEndDate () {
      return this.tpr.dealEndDate ? this.moment(this.tpr.dealEndDate).format(this.$config.date_format) : ''
    }
  },

  methods: {
    formatCurrencyInput (key, value) {
      this.tpr[key] = parseFloat(value).toFixed(2)
    },

    allowedTPRBegin: val => ![0, 1, 2, 3, 4, 5].includes(new Date(val).getDay()),

    allowedTPREnd: val => ![0, 1, 2, 3, 4, 6].includes(new Date(val).getDay()),

    getByItemCode () {
      this.$contracts.getByItemCode(this.tpr.orderCode, this.tpr.supplier.id)
        .then(response => {
          this.item = {
            id: response[0].itemCode,
            orderCode: response[0].orderCode,
            pack: response[0].pack,
            Size: response[0].size,
            description: response[0].description,
            upc: response[0].UPC
          }
          this.tpr.regSRPCount = response[0].regSRPCount
          this.tpr.regSRP = response[0].regSRP
        }).catch(err => {
          this.notify('error', err.response.data.msg)
        })
    },

    getDepartments () {
      this.$contracts.getDepartments(this.tpr.supplier.id)
        .then(response => {
          this.departments = response
        })
    },
    getManufacturers () {
      this.$contracts.getManufacturers()
        .then(response => {
          this.manufacturers = response
        })
    },

    async copyTPR () {
      this.tpr.dealBeginDate = this.formattedBeginDate
      this.tpr.dealEndDate = this.formattedEndDate
      this.tpr.item = cloneDeep(this.item)
      await this.$tprs.saveTPR(this.tpr)
        .then(() => {
          this.notify('success', 'TPR Successfully Added')
        }).catch(err => {
          try {
            this.notify('error', `${err.response.data.failure_reason}`)
          } catch {
            this.notify('error', 'TPR Not Copied')
          }
        })
      this.getTPRs()
    }
  }
}
</script>
