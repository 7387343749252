var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { value: _vm.value, persistent: "", width: "1300" }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(
                  "Copy TPR - " +
                    _vm._s(_vm.tpr.item.orderCode) +
                    " - " +
                    _vm._s(_vm.tpr.item.description)
                )
              ])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          label: "Order Code",
                          dense: "",
                          outlined: "",
                          autocomplete: "off"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getByItemCode($event)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: { click: _vm.getByItemCode }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-magnify")])],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.tpr.orderCode,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "orderCode", $$v)
                          },
                          expression: "tpr.orderCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { col: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Selection Flag",
                          value: _vm.item.selectionFlag
                            ? _vm.item.selectionFlag
                            : "N/A",
                          autcomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          value: _vm.item.description,
                          label: "Item Description",
                          autcomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          value: _vm.item.pack,
                          label: "Pack",
                          autcomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          value: _vm.item.Size,
                          label: "Size",
                          autcomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          value: _vm.item.upc,
                          label: "UPC",
                          autcomplete: "off"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "Case Cost",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "caseCost",
                              _vm.tpr.caseCost
                            )
                          }
                        },
                        model: {
                          value: _vm.tpr.caseCost,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "caseCost", $$v)
                          },
                          expression: "tpr.caseCost"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "AMAP",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput("tpr", _vm.tpr.AMAP)
                          }
                        },
                        model: {
                          value: _vm.tpr.AMAP,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "AMAP", $$v)
                          },
                          expression: "tpr.AMAP"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "EBA",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput("tpr", _vm.tpr.EBA)
                          }
                        },
                        model: {
                          value: _vm.tpr.EBA,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "EBA", $$v)
                          },
                          expression: "tpr.EBA"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "TPR BB",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput("BB", _vm.tpr.BB)
                          }
                        },
                        model: {
                          value: _vm.tpr.BB,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "BB", $$v)
                          },
                          expression: "tpr.BB"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "TPR Scan",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "scanAllow",
                              _vm.scanAllow
                            )
                          }
                        },
                        model: {
                          value: _vm.tpr.scanAllow,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "scanAllow", $$v)
                          },
                          expression: "tpr.scanAllow"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "ePay TPR",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput("ePay", _vm.ePay)
                          }
                        },
                        model: {
                          value: _vm.tpr.ePay,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "ePay", $$v)
                          },
                          expression: "tpr.ePay"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "max-width": "183px" },
                      attrs: { cols: "2" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Quantity",
                          type: "number",
                          min: "0",
                          autcomplete: "off"
                        },
                        model: {
                          value: _vm.tpr.regSRPCount,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "regSRPCount", $$v)
                          },
                          expression: "tpr.regSRPCount"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-icon", { staticClass: "mb-6" }, [_vm._v("mdi-at")]),
                  _c(
                    "v-col",
                    { staticClass: "srp", attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "Reg SRP",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "regSRP",
                              _vm.tpr.regSRP
                            )
                          }
                        },
                        model: {
                          value: _vm.tpr.regSRP,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "regSRP", $$v)
                          },
                          expression: "tpr.regSRP"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "max-width": "183px" },
                      attrs: { cols: "2" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Quantity",
                          type: "number",
                          min: "0",
                          autcomplete: "off"
                        },
                        model: {
                          value: _vm.tpr.specialSRPCount,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "specialSRPCount", $$v)
                          },
                          expression: "tpr.specialSRPCount"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-icon", { staticClass: "mb-6" }, [_vm._v("mdi-at")]),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        staticClass: "price",
                        attrs: {
                          "background-color": "#fff",
                          prefix: "$",
                          dense: "",
                          outlined: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          label: "TPR Retail",
                          autcomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.formatCurrencyInput(
                              "specialSRP",
                              _vm.tpr.specialSRP
                            )
                          }
                        },
                        model: {
                          value: _vm.tpr.specialSRP,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "specialSRP", $$v)
                          },
                          expression: "tpr.specialSRP"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Department",
                          items: _vm.departments,
                          "item-text": "name",
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.tpr.department,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "department", $$v)
                          },
                          expression: "tpr.department"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Manufacturer",
                          items: _vm.manufacturers,
                          "item-text": "description",
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.tpr.manufacturer,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "manufacturer", $$v)
                          },
                          expression: "tpr.manufacturer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Begin Date - Sun"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-inner",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { tabindex: "-1" } }, [
                                  _vm._v("mdi-calendar")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.tpr.dealBeginDate,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "dealBeginDate", $$v)
                          },
                          expression: "tpr.dealBeginDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "End Date - Sat"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-inner",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { tabindex: "-1" } }, [
                                  _vm._v("mdi-calendar")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.tpr.dealEndDate,
                          callback: function($$v) {
                            _vm.$set(_vm.tpr, "dealEndDate", $$v)
                          },
                          expression: "tpr.dealEndDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Contract",
                          value: _vm.tpr.contract.name
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          value: _vm.tpr.contract.id,
                          label: "Contract ID"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          value: _vm.tpr.comments,
                          label: "Comments"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("em", [
                      _c("h3", { staticStyle: { color: "#0c67a5" } }, [
                        _vm._v(_vm._s(_vm.tpr.adGroup.description))
                      ])
                    ])
                  ]),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#fafafa" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text ml-2",
                  attrs: { height: "40", color: "#00B24A" },
                  on: { click: _vm.copyTPR }
                },
                [
                  _vm._v(" Add"),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-plus")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }